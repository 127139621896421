import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductPost from "./product-post";
import ProductSidebar from "./product-sidebar";

const ProductDetails = ({ data }) => {
  return (
    <section className="service_details_area section_padding">
      <Container>
        <Row>
          <Col lg={8}>
            <ProductPost data={data} />
          </Col>
          <Col lg={4}>
            <ProductSidebar />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProductDetails;
