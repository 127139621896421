import React from "react";
// import servicedetails1 from "@/images/services/service-details.jpg";
import Img from "../img";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination]);
const ServicePost = ({ data }) => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    autoplay: {
      delay: 5000
    },
    navigation: true
  };
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  }
  return (
    <div className="service_details_left">
      <Swiper pagination={pagination} className="mySwiper" {...mainSlideOptions}>
        {data.banner.map((i, index) => 
          <SwiperSlide key={index}>
            <Img src={i} alt="Service Details Image" />
          </SwiperSlide>
        )}
      </Swiper>
      <h1>{data.title}</h1>
      {data.description.map(i => <p>{i}</p>)}
      {data.table &&
        <Row style={{ border: '1px solid', margin: '6px', marginBottom: '25px' }}>
          {data.table.map(i =>
            <Col style={{ border: '1px solid' }}>
              <ul>
                {i.map((j, index) => index === 0 ? <li style={{ color: 'black', fontSize: '18px' }}>{j}</li> : <li>{j}</li>)}
              </ul>
              
            </Col>
          )}
        </Row>
      }
      <h2>{data.optionTitle}</h2>
      <ul>
        {data.options.map(i => <li><i className="fa fa-check-circle"></i>{i}</li>)}
      </ul>
      
      
      <p className="extra_mr">
        {data.optionsFooter}
      </p>
      
      {/* {data.iconSection && <div className="service_benefits_area text-center">
        <Row>
          {data.icons.map(({ icon, title }, index) => (
            <Col lg={3} md={6} key={index}>
              <div className="service_benefits_box">
                <i className={icon}></i>
                <p>{title}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>} */}
      <h2>{data.faqQuestion}</h2>
      <ul className="service_details_ac">
        {data.faqOptions.map(i => <li><i className="fa fa-check-circle" />{i}</li>)}
      </ul>
      <p className="info_mr">
        {data.faqFooter}
      </p>
    </div>
  );
};

export default ServicePost;
