import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import { productDetails } from "@/data";
import ContactTwo from "@/components/contact-two";
import ProductDetails from "@/components/products/product-details";

const ServiceDetailsPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Products Details">
        <HeaderOne />
        <PageHeader title="Modular FRP Cooling Towers" name="Modular FRP Cooling Towers" />
        <ProductDetails data={productDetails.modularFrp}/>
        {/* <ContactTwo /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
